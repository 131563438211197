body, textarea, input {
    font-family: "proxima-nova", sans-serif;
    color: #58595B;
}

.clear-float {
    clear:both;
}

.highlight {
    color:#bbbbbb;
    font-size:40px;
}

img.display-left {
    float:left;
    margin-right:10px;
    margin-bottom:10px;
}

img.display-right {
    float:right;
    margin-left:10px;
    margin-bottom:10px;
}

.font-pale-blue {
    color: #B5C1DB;
}

.font-blue {
    color: #5781B3;
}

.font-dark-blue {
    color: #043859;
}

.col3 {
    width: 30%;
    display: inline-block;
    text-align: center;
    padding: 0 10px;
    vertical-align: top;
}

.col23 {
    width: 60%;
    display: inline-block;
    text-align: center;
    padding: 0 10px;
    vertical-align: top;
}